"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.matcher_group = void 0;
function matcher_group(matcher, matcher_group, exclude_indention) {
    var group_begin = 0;
    for (var group_index = 0; group_index < matcher_group - 1; group_index++) {
        group_begin += matcher[group_index + 1].length;
    }
    var group_indention_prefix_length = 0, group_indention_suffix_length = 0;
    if (exclude_indention === true) {
        var group_indention_prefix_matcher = matcher[matcher_group].match(/^(\s*)[^\s]/);
        var group_indention_suffix_matcher = matcher[matcher_group].match(/[^\s](\s*)$/);
        group_indention_prefix_length = group_indention_prefix_matcher ? group_indention_prefix_matcher[1].length : 0;
        group_indention_suffix_length = group_indention_suffix_matcher ? group_indention_suffix_matcher[1].length : 0;
    }
    return {
        begin_index: group_begin + group_indention_prefix_length,
        end_index: group_begin + matcher[matcher_group].length - group_indention_suffix_length - 1
    };
}
exports.matcher_group = matcher_group;
