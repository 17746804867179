"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.evaluate_expression = void 0;
var SkriptObject_1 = require("../objects/SkriptObject");
var expression_child_1 = require("../system/expression_child");
var reader_error_1 = require("../system/reader_error");
function evaluate_expression(script, divider, parent_object) {
    var expression_stage = false, expression_begin = -1;
    for (var script_index = 0; script_index < script.length; script_index++) {
        switch (script[script_index]) {
            case "%":
                if (expression_stage === false) {
                    expression_stage = true;
                    expression_begin = script_index;
                }
                else {
                    expression_stage = false;
                    divider.add_component({ begin_index: expression_begin, end_index: script_index, component_type: "expression" });
                }
                break;
            case "\"":
                if (expression_stage === false) {
                    break;
                }
                script_index += (0, expression_child_1.expression_child)(script.slice(script_index), parent_object) - 1;
                break;
            case "{":
                if (expression_stage === false) {
                    break;
                }
                script_index += (0, expression_child_1.expression_child)(script.slice(script_index), new SkriptObject_1.SkriptObjectTypeOnly("expression")) - 1;
                break;
        }
    }
    if (expression_stage === true) {
        throw (0, reader_error_1.reader_error)("incomplete expression statement", script.slice(expression_begin));
    }
    return divider;
}
exports.evaluate_expression = evaluate_expression;
