"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.evaluate_function_parameter_default = void 0;
var matcher_group_1 = require("../../system/matcher_group");
var reader_error_1 = require("../../system/reader_error");
function evaluate_function_parameter_default(script, divider) {
    var parameter_matcher = script.match(/^(\s*)([^:]+)(:\s*)([^= ][^=]*\s*)(?:=(.+))?$/);
    if (parameter_matcher === null) {
        throw (0, reader_error_1.reader_error)("invalid function header parameter", script);
    }
    if (parameter_matcher[5] === undefined) {
        return divider;
    }
    var parameter_default = (0, matcher_group_1.matcher_group)(parameter_matcher, 5, true);
    divider.add_component({ begin_index: parameter_default.begin_index + 1, end_index: parameter_default.end_index + 1, component_type: "function_parameter_default" });
    return divider;
}
exports.evaluate_function_parameter_default = evaluate_function_parameter_default;
