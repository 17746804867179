"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.evaluate_function = void 0;
var bracket_length_1 = require("../system/bracket_length");
var expression_child_1 = require("../system/expression_child");
var reader_error_1 = require("../system/reader_error");
function evaluate_function(script, divider, parent_object) {
    var function_stage = false, function_begin = -1;
    for (var script_index = 0; script_index < script.length; script_index++) {
        switch (script[script_index]) {
            case "(":
                var function_name_matcher = script.slice(0, script_index).match(/([\w\d]+)$/);
                var function_name_length = function_name_matcher === null ? 0 : function_name_matcher[0].length;
                if (function_stage === false && function_name_length > 0) {
                    function_stage = true;
                    function_begin = script_index - function_name_length;
                }
                else if (function_stage === true) {
                    script_index += (0, bracket_length_1.bracket_length)(script.slice(script_index), parent_object) - 1;
                }
                break;
            case ")":
                if (function_stage === true) {
                    function_stage = false;
                    divider.add_component({ begin_index: function_begin, end_index: script_index, component_type: "function" });
                }
                break;
            case "\"":
                script_index += (0, expression_child_1.expression_child)(script.slice(script_index), parent_object) - 1;
                break;
            case "{":
                script_index += (0, expression_child_1.expression_child)(script.slice(script_index), parent_object) - 1;
                break;
        }
    }
    if (function_stage === true) {
        throw (0, reader_error_1.reader_error)("incomplete function", script.slice(function_begin));
    }
    return divider;
}
exports.evaluate_function = evaluate_function;
