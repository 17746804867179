"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bracket_length = void 0;
var SkriptObject_1 = require("../objects/SkriptObject");
var expression_child_1 = require("./expression_child");
var reader_error_1 = require("./reader_error");
function bracket_length(script, parent_object) {
    var bracket_depth = 0, bracket_begin = -1;
    for (var script_index = 0; script_index < script.length; script_index++) {
        switch (script[script_index]) {
            case "(":
                if (bracket_depth === 0) {
                    bracket_begin = script_index;
                }
                bracket_depth++;
                break;
            case ")":
                bracket_depth--;
                if (bracket_depth === 0) {
                    return script_index - bracket_begin + 1;
                }
                else if (bracket_depth < 0) {
                    throw (0, reader_error_1.reader_error)("unexpected closing bracket", script.slice(bracket_begin === -1 ? 0 : bracket_begin));
                }
                break;
            case "\"":
                script_index += (0, expression_child_1.expression_child)(script.slice(script_index), parent_object) - 1;
                break;
            case "{":
                script_index += (0, expression_child_1.expression_child)(script.slice(script_index), new SkriptObject_1.SkriptObjectTypeOnly("body")) - 1;
                break;
        }
    }
    throw (0, reader_error_1.reader_error)("bracket not enclosed", script);
}
exports.bracket_length = bracket_length;
