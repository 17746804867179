"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.evaluate_string = void 0;
var expression_child_1 = require("../system/expression_child");
var reader_error_1 = require("../system/reader_error");
function evaluate_string(script, divider, parent_object, ignore_error) {
    if (ignore_error === void 0) { ignore_error = false; }
    var parents_include_string = parent_object.parent_types.includes("string");
    var string_stage = false, string_begin = -1;
    for (var script_index = 0; script_index < script.length; script_index++) {
        switch (script[script_index]) {
            case "\"":
                if (parents_include_string === false) {
                    if (string_stage === false) {
                        string_stage = true;
                        string_begin = script_index;
                    }
                    else if (script[script_index + 1] === "\"") {
                        script_index++;
                    }
                    else {
                        string_stage = false;
                        divider.add_component({ begin_index: string_begin, end_index: script_index, component_type: "string" });
                    }
                }
                else {
                    if (ignore_error !== true && script[script_index + 1] !== "\"") {
                        throw (0, reader_error_1.reader_error)("string literal not enclosed", script.slice(script_index));
                    }
                    else if (string_stage === false) {
                        string_stage = true;
                        string_begin = script_index;
                    }
                    else {
                        string_stage = false;
                        divider.add_component({ begin_index: string_begin, end_index: script_index + 1, component_type: "string" });
                    }
                    script_index++;
                }
                break;
            case "{":
                if (string_stage === true) {
                    break;
                }
                script_index += (0, expression_child_1.expression_child)(script.slice(script_index), parent_object) - 1;
                break;
        }
    }
    if (ignore_error !== true && string_stage === true) {
        throw (0, reader_error_1.reader_error)("string literal not enclosed", script.slice(string_begin));
    }
    return divider;
}
exports.evaluate_string = evaluate_string;
