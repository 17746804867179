"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.evaluate_variable = void 0;
var expression_child_1 = require("../system/expression_child");
var reader_error_1 = require("../system/reader_error");
function evaluate_variable(script, divider, parent_object, ignore_error) {
    if (ignore_error === void 0) { ignore_error = false; }
    var variable_stage = 0, variable_begin = -1;
    for (var script_index = 0; script_index < script.length; script_index++) {
        switch (script[script_index]) {
            case "{":
                if (variable_stage === 0) {
                    variable_begin = script_index;
                }
                variable_stage++;
                break;
            case "}":
                variable_stage--;
                if (variable_stage === 0) {
                    divider.add_component({ begin_index: variable_begin, end_index: script_index, component_type: "variable" });
                }
                else if (variable_stage < 0 && parent_object.object_type !== "variable" && parent_object.object_type !== "expression") {
                    throw (0, reader_error_1.reader_error)("unexpected variable enclose character '}'", script.slice(variable_begin, script_index + 1));
                }
                break;
            case "\"":
                script_index += (0, expression_child_1.expression_child)(script.slice(script_index), parent_object) - 1;
                break;
        }
    }
    if (ignore_error === false && variable_stage > 0) {
        throw (0, reader_error_1.reader_error)("variable not enclosed", script.slice(variable_begin));
    }
    return divider;
}
exports.evaluate_variable = evaluate_variable;
