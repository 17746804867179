"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.evaluate_comment = void 0;
function evaluate_comment(script, divider) {
    for (var script_index = 0; script_index < script.length; script_index++) {
        if (script[script_index] === "#" && script[script_index + 1] === "#") {
            script_index++;
            continue;
        }
        else if (script[script_index] === "#" && script[script_index + 1] !== "#") {
            divider.add_component({ begin_index: script_index, end_index: script.length - 1, component_type: "comment" });
            return divider;
        }
    }
    return divider;
}
exports.evaluate_comment = evaluate_comment;
