"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.evaluate_function_parameter = void 0;
var SkriptObject_1 = require("../../objects/SkriptObject");
var bracket_length_1 = require("../../system/bracket_length");
var expression_child_1 = require("../../system/expression_child");
var reader_error_1 = require("../../system/reader_error");
function evaluate_function_parameter(script, divider) {
    var function_parameter_matcher = script.match(/^([\w\d]+)\((.*)\)$/);
    if (function_parameter_matcher === null) {
        return divider;
    }
    var function_parameter = function_parameter_matcher && function_parameter_matcher[2] !== "" ? function_parameter_matcher[2] + "," : "";
    var function_parameter_begin = function_parameter_matcher[1].length + 1;
    var parameter_begin = 0;
    for (var parameter_index = 0; parameter_index < function_parameter.length; parameter_index++) {
        switch (function_parameter[parameter_index]) {
            case ",":
                var parameter_string = function_parameter.slice(parameter_begin, parameter_index);
                var parameter_matcher = parameter_string.match(/^(\s*)([^:]+)(:\s*)([^= ][^=]*\s*)(?:=(.+))?$/);
                if (parameter_matcher === null) {
                    throw (0, reader_error_1.reader_error)("invalid function header parameter", parameter_string);
                }
                var parameter_indention_prefix = parameter_matcher[1] ? parameter_matcher[1].length : 0;
                var parameter_indention_suffix_matcher = parameter_string.match(/(\s*)$/);
                var parameter_indention_suffix = parameter_indention_suffix_matcher ? parameter_indention_suffix_matcher[1].length : 0;
                divider.add_component({
                    begin_index: function_parameter_begin + parameter_begin + parameter_indention_prefix,
                    end_index: function_parameter_begin + parameter_index - parameter_indention_suffix - 1,
                    component_type: "function_parameter"
                });
                parameter_begin = parameter_index + 1;
                break;
            case "(":
                parameter_index += (0, bracket_length_1.bracket_length)(function_parameter.slice(parameter_index), new SkriptObject_1.SkriptObjectTypeOnly("function")) - 1;
                break;
            case "\"":
                parameter_index += (0, expression_child_1.expression_child)(function_parameter.slice(parameter_index), new SkriptObject_1.SkriptObjectTypeOnly("function")) - 1;
                break;
            case "{":
                parameter_index += (0, expression_child_1.expression_child)(function_parameter.slice(parameter_index), new SkriptObject_1.SkriptObjectTypeOnly("function")) - 1;
                break;
        }
    }
    return divider;
}
exports.evaluate_function_parameter = evaluate_function_parameter;
