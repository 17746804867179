"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SkriptObjectTypeOnly = exports.SkriptObject = void 0;
var evaluate_string_1 = require("../reader/evaluate_string");
var SkriptDivider_1 = require("./SkriptDivider");
var evaluate_variable_1 = require("../reader/evaluate_variable");
var evaluate_number_1 = require("../reader/evaluate_number");
var evaluate_expression_1 = require("../reader/evaluate_expression");
var evaluate_comment_1 = require("../reader/evaluate_comment");
var evaluate_indention_1 = require("../reader/evaluate_indention");
var evaluate_function_1 = require("../reader/evaluate_function");
var evaluate_keyword_1 = require("../reader/evaluate_keyword");
var evaluate_function_parameter_1 = require("../reader/function_header/evaluate_function_parameter");
var evaluate_function_parameter_name_1 = require("../reader/function_header/evaluate_function_parameter_name");
var evaluate_function_parameter_type_1 = require("../reader/function_header/evaluate_function_parameter_type");
var evaluate_function_parameter_default_1 = require("../reader/function_header/evaluate_function_parameter_default");
var object_type_components = __importStar(require("../data/object_component_types.json"));
var SkriptObject = (function () {
    function SkriptObject(content, type, depth, parent_types, parent_data, final) {
        this.object_data = {};
        this.object_content = content;
        this.object_type = type;
        this.object_depth = depth;
        this.parent_types = parent_types;
        this.object_data = {
            object_final: final,
            function_header: (depth === 0 ? content.match(/^(\s*function )(.+)$/) !== null : parent_data.function_header)
        };
        this.inner_components = final ? [] : this.evaluate_components();
    }
    SkriptObject.prototype.collapse_components = function () {
        if (this.inner_components.length <= 0) {
            return [this];
        }
        var inner_component_collapsed = [];
        for (var inner_component_index = 0; inner_component_index < this.inner_components.length; inner_component_index++) {
            inner_component_collapsed = inner_component_collapsed.concat(this.inner_components[inner_component_index].collapse_components());
        }
        return inner_component_collapsed;
    };
    SkriptObject.prototype.evaluate_components = function () {
        if (this.object_content.length <= 0) {
            return [];
        }
        var type = this.object_type.toString();
        var type_components = object_type_components[type].child_components;
        var component_divider = new SkriptDivider_1.SkriptDivider();
        var content_without_comment = this.object_content;
        for (var type_components_index = 0; type_components_index < type_components.length; type_components_index++) {
            var loop_type_component = type_components[type_components_index];
            var loop_type_maximum_depth = object_type_components[loop_type_component].maximum_depth;
            if (loop_type_maximum_depth !== null && loop_type_maximum_depth < this.object_depth + 1) {
                continue;
            }
            switch (loop_type_component) {
                case "string":
                    component_divider = (0, evaluate_string_1.evaluate_string)(content_without_comment, component_divider, this);
                    break;
                case "variable":
                    component_divider = (0, evaluate_variable_1.evaluate_variable)(content_without_comment, component_divider, this);
                    break;
                case "number":
                    component_divider = (0, evaluate_number_1.evaluate_number)(content_without_comment, component_divider);
                    break;
                case "expression":
                    component_divider = (0, evaluate_expression_1.evaluate_expression)(content_without_comment, component_divider, this);
                    break;
                case "function":
                    component_divider = (0, evaluate_function_1.evaluate_function)(content_without_comment, component_divider, this);
                    break;
                case "indention":
                    component_divider = (0, evaluate_indention_1.evaluate_indention)(content_without_comment, component_divider);
                    break;
                case "comment":
                    component_divider = (0, evaluate_comment_1.evaluate_comment)(content_without_comment, component_divider);
                    var comment_content = component_divider.get_component_by_type("comment");
                    content_without_comment = comment_content.length > 0 ? this.object_content.substring(0, comment_content[0].begin_index) : this.object_content;
                    break;
                case "function_parameter":
                    if (this.object_data.function_header === false) {
                        break;
                    }
                    component_divider = (0, evaluate_function_parameter_1.evaluate_function_parameter)(content_without_comment, component_divider);
                    break;
                case "function_parameter_name":
                    component_divider = (0, evaluate_function_parameter_name_1.evaluate_function_parameter_name)(content_without_comment, component_divider);
                    break;
                case "function_parameter_type":
                    component_divider = (0, evaluate_function_parameter_type_1.evaluate_function_parameter_type)(content_without_comment, component_divider);
                    break;
                case "function_parameter_default":
                    component_divider = (0, evaluate_function_parameter_default_1.evaluate_function_parameter_default)(content_without_comment, component_divider);
                    break;
                default:
                    var keyword_types = ["boolean"];
                    if (keyword_types.includes(loop_type_component)) {
                        component_divider = (0, evaluate_keyword_1.evaluate_keyword)(content_without_comment, component_divider, this, loop_type_component);
                    }
                    break;
            }
        }
        return component_divider.export_component(this.object_content, this);
    };
    return SkriptObject;
}());
exports.SkriptObject = SkriptObject;
;
var SkriptObjectTypeOnly = (function (_super) {
    __extends(SkriptObjectTypeOnly, _super);
    function SkriptObjectTypeOnly(type) {
        return _super.call(this, "", type, -1, [], {}, true) || this;
    }
    return SkriptObjectTypeOnly;
}(SkriptObject));
exports.SkriptObjectTypeOnly = SkriptObjectTypeOnly;
