"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.evaluate_indention = void 0;
function evaluate_indention(script, divider) {
    var indention_matcher = script.match(/^(\s+)/);
    if (indention_matcher === null) {
        return divider;
    }
    divider.add_component({ begin_index: 0, end_index: indention_matcher[1].length - 1, component_type: "indention" });
    return divider;
}
exports.evaluate_indention = evaluate_indention;
