"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SkriptDivider = void 0;
var SkriptObject_1 = require("./SkriptObject");
;
var SkriptDivider = (function () {
    function SkriptDivider() {
        this.components = [];
    }
    SkriptDivider.prototype.add_component = function (component) {
        var override = this.override_component(component.begin_index, component.end_index);
        if (override.length > 0) {
            return;
        }
        this.components.push(component);
    };
    SkriptDivider.prototype.get_component = function () {
        this.sort_component();
        return this.components;
    };
    SkriptDivider.prototype.get_component_by_type = function (component_type) {
        return this.components.filter(function (loop_component) { return loop_component.component_type === component_type; });
    };
    SkriptDivider.prototype.override_component = function (begin, end) {
        return this.components.filter(function (loop_component) { return loop_component.begin_index <= end && loop_component.end_index >= begin; });
    };
    SkriptDivider.prototype.export_component = function (script, parent_object) {
        this.sort_component();
        var export_objects = [], next_index = 0;
        for (var component_index = 0; component_index < this.components.length; component_index++) {
            var loop_component = this.components[component_index];
            if (loop_component.begin_index >= next_index + 1) {
                export_objects.push(divider_component_to_object(script, { begin_index: next_index, end_index: loop_component.begin_index - 1, component_type: parent_object.object_type }, parent_object.object_depth, parent_object.parent_types, parent_object.object_data, true));
            }
            var loop_component_parent_types = __spreadArray([], parent_object.parent_types, true);
            if (!loop_component_parent_types.includes(loop_component.component_type)) {
                loop_component_parent_types.push(loop_component.component_type);
            }
            export_objects.push(divider_component_to_object(script, loop_component, parent_object.object_depth, loop_component_parent_types, parent_object.object_data, false));
            next_index = loop_component.end_index + 1;
        }
        if (this.components.length > 0 && script.length >= next_index + 1) {
            export_objects.push(divider_component_to_object(script, { begin_index: next_index, end_index: script.length - 1, component_type: parent_object.object_type }, parent_object.object_depth, parent_object.parent_types, parent_object.object_data, true));
        }
        return export_objects;
    };
    SkriptDivider.prototype.sort_component = function () {
        this.components = this.components.sort(function (component_1, component_2) { return component_1.begin_index - component_2.begin_index; });
    };
    return SkriptDivider;
}());
exports.SkriptDivider = SkriptDivider;
function divider_component_to_object(script, divider_component, parent_depth, parent_types, parent_data, object_final) {
    var script_component = script.slice(divider_component.begin_index, divider_component.end_index + 1);
    return new SkriptObject_1.SkriptObject(script_component, divider_component.component_type, parent_depth + 1, parent_types, parent_data, object_final);
}
