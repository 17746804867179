"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.evaluate_number = void 0;
function evaluate_number(script, divider) {
    var number_pattern = /(?:-)?(?:\d*\.)?\d+/g;
    var number_pattern_match;
    while (number_pattern_match = number_pattern.exec(script)) {
        divider.add_component({ begin_index: number_pattern_match.index, end_index: number_pattern.lastIndex - 1, component_type: "number" });
    }
    return divider;
}
exports.evaluate_number = evaluate_number;
