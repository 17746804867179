"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.evaluate_keyword = void 0;
var expression_child_1 = require("../system/expression_child");
var object_type_components = __importStar(require("../data/object_component_types.json"));
;
function evaluate_keyword(script, divider, parent_object, keyword_type) {
    var _a;
    var script_parts = [], last_append_index = -2;
    for (var script_index = 0; script_index < script.length; script_index++) {
        switch (script[script_index]) {
            case "\"":
                script_index += (0, expression_child_1.expression_child)(script.slice(script_index), parent_object) - 1;
                break;
            case "{":
                script_index += (0, expression_child_1.expression_child)(script.slice(script_index), parent_object) - 1;
                break;
            default:
                if (last_append_index + 1 < script_index) {
                    script_parts.push({ content: "", begin_index: script_index });
                }
                script_parts[script_parts.length - 1].content += script[script_index];
                last_append_index = script_index;
                break;
        }
    }
    for (var script_part_index = 0; script_part_index < script_parts.length; script_part_index++) {
        var word_matcher = /([^\w-])?(\w+)([^\w-])?/g;
        var result = void 0;
        var _loop_1 = function () {
            var word_match = result[2].toLowerCase();
            var word_match_prefix = result[1] !== undefined ? result[1] : "";
            var word_match_index = script_parts[script_part_index].begin_index + result.index + word_match_prefix.length;
            if (((_a = object_type_components[keyword_type].keywords) === null || _a === void 0 ? void 0 : _a.find(function (keyword) { return new RegExp(keyword).test(word_match.toLowerCase()) === true; })) !== undefined) {
                divider.add_component({ begin_index: word_match_index, end_index: word_match_index + result[2].length - 1, component_type: keyword_type });
            }
        };
        while ((result = word_matcher.exec(script_parts[script_part_index].content)) !== null) {
            _loop_1();
        }
    }
    return divider;
}
exports.evaluate_keyword = evaluate_keyword;
