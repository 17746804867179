"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.evaluate_function_parameter_name = void 0;
var matcher_group_1 = require("../../system/matcher_group");
var reader_error_1 = require("../../system/reader_error");
function evaluate_function_parameter_name(script, divider) {
    var parameter_matcher = script.match(/^(\s*)([^:]+)(:\s*)([^= ][^=]*\s*)(?:=(.+))?$/);
    if (parameter_matcher === null) {
        throw (0, reader_error_1.reader_error)("invalid function header parameter", script);
    }
    var parameter_name = (0, matcher_group_1.matcher_group)(parameter_matcher, 2, true);
    divider.add_component({ begin_index: parameter_name.begin_index, end_index: parameter_name.end_index, component_type: "function_parameter_name" });
    return divider;
}
exports.evaluate_function_parameter_name = evaluate_function_parameter_name;
