"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.expression_child = void 0;
var SkriptDivider_1 = require("../objects/SkriptDivider");
var evaluate_string_1 = require("../reader/evaluate_string");
var evaluate_variable_1 = require("../reader/evaluate_variable");
function expression_child(script, parent_object) {
    switch (script[0]) {
        case "\"":
            var ignore_string_component = (0, evaluate_string_1.evaluate_string)(script, new SkriptDivider_1.SkriptDivider(), parent_object, true).get_component();
            if (ignore_string_component.length <= 0) {
                return 1;
            }
            return ignore_string_component[0].end_index - ignore_string_component[0].begin_index + 1;
        case "{":
            var ignore_variable_component = (0, evaluate_variable_1.evaluate_variable)(script, new SkriptDivider_1.SkriptDivider(), parent_object, true).get_component();
            if (ignore_variable_component.length <= 0) {
                return 1;
            }
            return ignore_variable_component[0].end_index - ignore_variable_component[0].begin_index + 1;
        default:
            return 1;
    }
}
exports.expression_child = expression_child;
